import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";

const state = {
	/**
	 * Array of the oneMinutes of the museum
	 * @type {Array}
	 */
	oneMinutes: [],

	/**
	 * Will be true when there is an error fetching oneMinutes from the museum
	 * @type {boolean}
	 */
	oneMinutesError: false,

	/**
	 * Object representation of the current oneMinute
	 * @type {Object}
	 */
	oneMinute: null,

	/**
	 * Will be true when there is an error fetching the current oneMinute
	 * @type {boolean}
	 */
	oneMinuteError: false,
};

const getters = {

};

const actions = {
	/**
	 * Gets the oneMinutes of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 */
	getOneMinutes: ({ rootState, state, commit }) => {
		debug.open("getOneMinutes");
		commit(mutationTypes.SET_ONE_MINUTES_ERROR, false);

		if(rootState.museum.museum && rootState.museum.museum.oneMinutes) {
			let oneMinutes = [];

			for(let index in rootState.museum.museum.oneMinutes) {
				let oneMinute = rootState.museum.museum.oneMinutes[index];
				oneMinute.locale = findLocalization(oneMinute);
				oneMinutes.push(oneMinute);
			}

			commit(mutationTypes.SET_ONE_MINUTES, oneMinutes);
			debug.log("Found " + oneMinutes.length + " oneMinutes");

			if(state.oneMinutes.length < 1) {
				debug.log("There are no oneMinutes in this museum");
				commit(mutationTypes.SET_ONE_MINUTES_ERROR, true);
			}
		} else {
			debug.log("There are no oneMinutes in this museum");
			commit(mutationTypes.SET_ONE_MINUTES_ERROR, true);
		}
		debug.close();
	},

	/**
	 * Gets a oneMinute from the museum based on the given id
	 * @param state
	 * @param commit
	 * @param {(number|string)} idOneMinute - The id of the oneMinute
	 */
	findOneMinute: ({ commit, rootState }, idOneMinute) => {
		debug.open("findOneMinute");
		commit(mutationTypes.SET_ONE_MINUTE_ERROR, false);

		let oneMinute = rootState.museum.museum.oneMinutes.find(oneMinute => oneMinute.idOneMinute === parseInt(idOneMinute));
		commit(mutationTypes.SET_ONE_MINUTE, oneMinute);

		if (!state.oneMinute) {
			debug.log("OneMinute " + idOneMinute + " could not be found in this museum");
			commit(mutationTypes.SET_ONE_MINUTE_ERROR, true);
		} else {
			debug.log("OneMinute " + idOneMinute + " found");
			oneMinute.locale = findLocalization(oneMinute);
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_ONE_MINUTES]: (state, value) => state.oneMinutes = value,
	[mutationTypes.SET_ONE_MINUTE]: (state, value) => state.oneMinute = value,
	[mutationTypes.SET_ONE_MINUTES_ERROR]: (state, value) => state.oneMinutesError = value,
	[mutationTypes.SET_ONE_MINUTE_ERROR]: (state, value) => state.oneMinuteError = value,
};

export default {
	moduleName: 'oneMinute',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
