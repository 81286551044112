import GenericClass from "@/models/generic-class";

export default class Visit extends GenericClass {
	idVisit; //number
	visitorToken; // string;
	idDevice; // string;
	startDate; // Date;
	endDate; // Date;
	languageCode; // string;
	idMuseum; // number;
	email; // string;
	artworks; // ArtworkVisit;
	routes; // RouteVisit;

	constructor(visitorToken, idDevice, startDate, languageCode, idMuseum, idVisit = null) {
		super();
		this.idVisit = idVisit;
		this.visitorToken = visitorToken;
		this.idDevice = idDevice;
		this.startDate = startDate;
		this.languageCode = languageCode;
		this.idMuseum = idMuseum;
		this.artworks = [];
		this.routes = [];
	}
}
