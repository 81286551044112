import api from "./base/API.js";

const resource = "SocialNetwork";
export default {
	get() {
		return api.get(resource);
	},

	createUser(profile) {
		return api.post(resource + "/", profile);
	},
};
