import store from '@/store';
import * as mutationTypes from '@/store/mutation-types';

store.commit('app/' + mutationTypes.SET_IS_NETWORK_ONLINE, navigator.onLine);

/**
 * Listens to a network status change to update the isNetworkOnline variable to true
 * when the network is turned on
 */
window.addEventListener('online', () =>
	store.commit('app/' + mutationTypes.SET_IS_NETWORK_ONLINE, true)
);

/**
 * Listens to a network status change to update the isNetworkOnline variable to false
 * when the network is turned off
 */
window.addEventListener('offline', () =>
	store.commit('app/' + mutationTypes.SET_IS_NETWORK_ONLINE, false)
);
