import MuseumsAPI from "@/api/MuseumsAPI";
import CustomizationsAPI from "@/api/CustomizationsAPI";
import UsersAPI from "@/api/UsersAPI";
import ArtworksAPI from "@/api/ArtworksAPI";
import BeaconsAPI from "@/api/BeaconsAPI";
import InfoSectionsAPI from "@/api/InfoSectionsAPI";
import OneMinutesAPI from "@/api/OneMinutesAPI";
import RoutesAPI from "@/api/RoutesAPI";
import TimelinesAPI from "@/api/TimelinesAPI";

const apis = {
	museums: MuseumsAPI,
	customizations: CustomizationsAPI,
	users: UsersAPI,
	artworks: ArtworksAPI,
	beacons: BeaconsAPI,
	infoSections: InfoSectionsAPI,
	oneMinutes: OneMinutesAPI,
	routes: RoutesAPI,
	timelines: TimelinesAPI,
};

export const APIFactory = {
	get: (name) => apis[name]
};
