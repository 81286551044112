import GenericClass from "@/models/generic-class";

export default class Timeline extends GenericClass {
	active; // boolean;
	artworkCount; //number;
	artworkIDs; //Array;
	hallCount; //number;
	halls; //Array;
	idTimeline; //number;
	locale; //Object;
	localizations; //Array;
	mainImageUrl;
	minutes; //number;
	steps; //Array;
	updateAt; //Date;

	constructor() {
		super();
	}
}
