import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import {findLocalization} from "@/store/helpers";

const state = {
    /**
     * Object representation of the beacon
     * @type {Object}
     */
    beacon: null,

    /**
     * Will be true when there is an error fetching the beacon
     * @type {boolean}
     */
    beaconError: false
};

const getters = {};

const actions = {
    /**
     * Gets a beacon from the museum based on the given id
     * @param state
     * @param commit
     * @param {(number|string)} idBeacon - The id of the beacon
     */
    getBeacon: ({rootState, commit}, idBeacon) => {
        commit(mutationTypes.SET_BEACON_ERROR, false);

        if (rootState.museum.museum && rootState.museum.museum.beacons) {
             let beacon = rootState.museum.museum.beacons.find(beacon => beacon.idBeacon === idBeacon);
                debug.log("[findBeacon] " + (beacon ? "Found" : "Not found") + " beacon with id " + idBeacon + " in this museum");
                if (beacon) {
                    beacon.locale = findLocalization(beacon.resource);
                    debug.log("[findBeacon] Beacon " + beacon.idBeacon + " locale " + (beacon.locale ? "found" : "not found"));
                    if (beacon.locale) {
                        beacon.name = beacon.locale.name;
                    }

                    beacon.background = rootState.artwork.artworks.length ? rootState.artwork.artworks[0].mainImageUrl : rootState.museum.museum.artworks[0].mainImageUrl;

                }
                commit(mutationTypes.SET_BEACON, beacon);


        } else {
            debug.log("[getBeacon] There are no beacons in this museum");
            commit(mutationTypes.SET_BEACON_ERROR, true);
        }
    },
};

const mutations = {
    [mutationTypes.SET_BEACON]: (state, value) => state.beacon = value,
    [mutationTypes.SET_BEACON_ERROR]: (state, value) => state.beaconError = value
};

export default {
    moduleName: 'beacon',
    statePropName: 'data',
    namespaced: true,

    state,
    getters,
    actions,
    mutations
};
