import api from "./base/API.js";

const resource = "Timeline";
export default {
	get() {
		return api.get(resource);
	},

	getTimeline(id) {
		return api.get(resource + "/" + id);
	},
};
