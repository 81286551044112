import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";

const state = {
	/**
	 * Array of the slides of the museum
	 * @type {Array}
	 */
	slides: [],

	/**
	 * Will be true when there is an error fetching slides from the museum
	 * @type {boolean}
	 */
	slidesError: false,

	/**
	 * Object representation of the current slide
	 * @type {Object}
	 */
	slide: null,

	/**
	 * Will be true when there is an error fetching the current slide
	 * @type {boolean}
	 */
	slideError: false,
};

const getters = {

};

const actions = {
	/**
	 * Gets the slides for the walkthrough of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 */
	getSlides: ({ rootState, state, commit }) => {
		debug.open("getSlides");
		commit(mutationTypes.SET_SLIDES_ERROR, false);

		if(rootState.museum.museum && rootState.museum.museum.slides) {
			let slides = [];

			for(let index in rootState.museum.museum.slides) {
				let slide = rootState.museum.museum.slides[index];
				if(slide.textResource?.localizations) {
					slide.textResource.locale = findLocalization(slide.textResource);
				}
				if(slide.videoResource?.localizations) {
					slide.videoResource.locale = findLocalization(slide.videoResource);
				}

				slide.index = slides.length;
				slides.push(slide);
			}

			commit(mutationTypes.SET_SLIDES, slides);
			debug.log("Found " + slides.length + " slides");

			if(state.slides.length < 1) {
				debug.log("There are no slides in this museum");
				commit(mutationTypes.SET_SLIDES_ERROR, true);
			}
		} else {
			debug.log("There are no slides in this museum");
			commit(mutationTypes.SET_SLIDES_ERROR, true);
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_SLIDES]: (state, value) => state.slides = value,
	[mutationTypes.SET_SLIDE]: (state, value) => state.slide = value,
	[mutationTypes.SET_SLIDES_ERROR]: (state, value) => state.slidesError = value,
	[mutationTypes.SET_SLIDE_ERROR]: (state, value) => state.slideError = value,
};

export default {
	moduleName: 'slide',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
