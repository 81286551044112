import * as mutationTypes from "@/store/mutation-types";
import Visit from "@/models/visit";
import idb from "@/api/base/idb";
import {debug} from "@/misc/debug";
import {museumResources} from "@/store/helpers";

const state = {
	/**
	 * Indicates if there are long/async processes being done
	 * @type {boolean}
	 */
	isLoading: false,

	/**
	 * Indicates if the user has saved the multimedia resources offline or not
	 * @type {boolean}
	 */
	isNetworkOnline: true,

	/**
	 * Used when there is new content to be downloaded
	 */
	serviceWorkerRegistrationForNewContent: null,

	/**
	 * Indicates if the app content is being refreshed
	 * @type {boolean}
	 */
	refreshingApp: false,

	/**
	 * The view from where the user has accessed the artwork view to change the color of the buttons
	 * @type {Object}
	 */
	viewFrom: { name: 'Keyboard', params: {} },

	/**
	 * The user that is currently logged in
	 * @type {Object}
	 */
	user: localStorage.getItem('user'),

	/**
	 * The access code of the museum
	 * @type {string}
	 */
	code: localStorage.getItem('code'),

	/**
	 * The resources needed to be re-downloaded
	 * @type {Array}
	 */
	resourcesOutOfDB: [],

	/**
	 * Statistics of the visit
	 * @type {Visit}
	 */
	visit: JSON.parse(localStorage.getItem('visit')) || new Visit(),

	visitv2: JSON.parse(localStorage.getItem('visitv2')) || {},
};

const getters = {
	newContentAvailable: state => (state.serviceWorkerRegistrationForNewContent) !== null
									&& (state.serviceWorkerRegistrationForNewContent) !== undefined
};

const actions = {
	/**
	 * Trigger service worker skipWaiting so the new service worker can take over.
	 * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
	 */
	serviceWorkerSkipWaiting({ getters, commit }) {
		if (!getters.newContentAvailable) return;

		commit(mutationTypes.SET_REFRESHING_APP, true);
		state.serviceWorkerRegistrationForNewContent.waiting.postMessage('skipWaiting');
	},

	/**
	 * Resets all the properties of the museum in the localStorage and memory to change it for another one
	 * @param commit
	 */
	async resetMuseum({commit}) {
		debug.open('resetMuseum');
		debug.log("Resetting museum");
		localStorage.clear();
		for (let resource of museumResources) {
			await idb.deleteAllInDB(resource)
				.catch((error) => {
					console.error(error);
				});
		}
		commit(mutationTypes.SET_CODE, null);
		commit(mutationTypes.SET_USER, null);
		commit('museum/' + mutationTypes.SET_MUSEUM, null, {root: true});
		localStorage.setItem('version', process.env.VUE_APP_VERSION);
		debug.close();
	},

	/**
	 * Fetches the museum model from the API
	 * @param store
	 * @param {string, number} idMuseum - The id of the museum that is being loaded
	 * @returns {Promise<unknown>} Indicates if the museum model could be fetched or not
	 */
	loadMuseumPresentation({ rootState, dispatch }, idMuseum) {
		return new Promise((resolve, reject) => {
			debug.open('loadMuseumPresentation');
			if(!rootState.museum.museum || (rootState.museum.museum && !rootState.museum.museum.resolvedResources)) {
				debug.log("Fetching the museum");

				dispatch('museum/fetchMuseum', idMuseum, {root: true})
					.then(() => {
						debug.close();
						resolve();
					})

					.catch((error) => {
						debug.log("There was a problem loading the museum presentation");
						console.error(error);
						debug.close();
						reject();
					});
			} else {
				debug.log("Museum " + rootState.museum.museum.idMuseum + ' was already loaded');
				debug.close();

				resolve();
			}
		});
	},

	/**
	 * Fetches a detailed museum model with all its resources from the API
	 * @param store
	 * @param {(string|number)} idMuseum - The id of the museum that is being loaded
	 * @returns {Promise<unknown>} Indicates if the museum model could be fetched or not
	 */
	loadMuseum({ rootState, dispatch }, idMuseum) {
		debug.open("loadMuseum");
		return new Promise((resolve, ) => {
			if (!rootState.museum.museum || (rootState.museum.museum && !rootState.museum.museum.resolvedResources)) {
				debug.log("Searching for the museum");

				dispatch('museum/fetchMuseumDetail', idMuseum, {root: true})
					.catch(async () => {
						debug.log("Fetching museum details");
						await dispatch('museum/fetchMuseumDetail', idMuseum, {root: true});
					})

					.finally(() => {
						debug.log("Fully loaded museum");
						debug.close();
						resolve();
					});
			} else {
				debug.log("Museum " + rootState.museum.museum.idMuseum + ' was already loaded');
				debug.close();
				resolve();
			}
		});
	},
};

const mutations = {
	[mutationTypes.SET_IS_LOADING]: (state, value) => state.isLoading = value,
	[mutationTypes.SET_IS_NETWORK_ONLINE]: (state, value) => state.isNetworkOnline = value,
	[mutationTypes.SET_SERVICE_WORKER_REGISTRATION_NEW_CONTENT]: (state, value) => state.serviceWorkerWRegistrationForNewContent = value,
	[mutationTypes.SET_REFRESHING_APP]: (state, value) => state.refreshingApp = value,
	[mutationTypes.SET_VIEW_FROM]: (state, value) => state.viewFrom = value,
	[mutationTypes.SET_USER]: (state, value) => state.user = value,
	[mutationTypes.SET_CODE]: (state, value) => state.code = value,
	[mutationTypes.SET_RESOURCES_OUT_OF_DB]: (state, value) => state.resourcesOutOfDB = value,
	[mutationTypes.SET_VISIT]: (state, value) => state.visit = value,
	[mutationTypes.SET_VISITV2]: (state, value) => state.visitv2 = value,
	[mutationTypes.UPDATE_VISIT_END_DATE]: (state, value) => {
		state.visit.endDate = value;
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.ADD_ARTWORK_VISIT]: (state, value) => {
		state.visit.artworks.push(value);
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.UPDATE_LAST_ARTWORK_VISIT]: (state) => {
		let visit = state.visit.artworks.pop();
		let currentTime = document.getElementsByClassName("player")[0]?.firstChild.currentTime || 0;
		let newEndDate = new Date(visit.startDate);
		visit.endDate = new Date(newEndDate.getTime() + (currentTime * 1000));
		state.visit.artworks.push(visit);
		localStorage.removeItem('current-artwork-visit');
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.DELETE_ARTWORK_VISIT]: (state) => {
		state.visit.artworks = [];
		localStorage.removeItem('current-artwork-visit');
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.ADD_ROUTE_VISIT]: (state, value) => {
		state.visit.routes.push(value);
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.DELETE_ROUTE_VISIT]: (state) => {
		state.visit.routes = [];
		localStorage.removeItem('current-route-visit');
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.UPDATE_LAST_ROUTE_VISIT]: (state) => {
		let visit = state.visit.routes.pop();
		visit.endDate = new Date();
		state.visit.routes.push(visit);
		localStorage.removeItem('current-route-visit');
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
	[mutationTypes.UPDATE_ID_VISIT]: (state, value) => {
		state.visit.idVisit = value;
		localStorage.setItem('visit', JSON.stringify(state.visit));
	},
};

export default {
	moduleName: 'app',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
