<template>
	<transition name="slide">
		<aside class="aside-content">
			<ul>
				<li v-if="!sideMenu || (sideMenu && sideMenu.startEnabled && !sideMenu.startHighlighted)" @click="goTo('Options')">
					<i class="mm-icon mm-icon-home gray"/>
					{{ (sideMenu && sideMenu.startEnabled && sideMenu.locale && sideMenu.locale.startText)
						? sideMenu.locale.startText
						: $t('start') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.inAMinuteEnabled && !sideMenu.inAMinuteHighlighted)" @click="goTo('OneMinuteList')">
					<i class="mm-icon mm-icon-time gray"/>
					{{ (sideMenu && sideMenu.inAMinuteEnabled && sideMenu.locale && sideMenu.locale.inAMinuteText)
						? sideMenu.locale.inAMinuteText
						: $t('in_a_minute') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.outstandingArtworksEnabled && !sideMenu.outstandingArtworksHighlighted)" @click="goTo('OutstandingArtworks')">
					<i class="mm-icon mm-icon-star gray"/>
					{{ (sideMenu && sideMenu.outstandingArtworksEnabled && sideMenu.locale && sideMenu.locale.outstandingArtworksText)
						? sideMenu.locale.outstandingArtworksText
						: $t('most_outstanding_artworks') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.languageEnabled && !sideMenu.languageHighlighted)" @click="goTo('Languages')">
					<i class="mm-icon mm-icon-languages gray"/>
					{{ (sideMenu && sideMenu.languageEnabled && sideMenu.locale && sideMenu.locale.languageText)
						? sideMenu.locale.languageText
						: $t('language') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.exploreMuseumEnabled && !sideMenu.exploreMuseumHighlighted)" @click="goTo('InfoSectionList')">
					<i class="mm-icon mm-icon-museum gray"/>
					{{ (sideMenu && sideMenu.exploreMuseumEnabled && sideMenu.locale && sideMenu.locale.exploreMuseumText)
						? sideMenu.locale.exploreMuseumText
						: $t('explore_museum') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.guideEnabled && !sideMenu.guideHighlighted)" @click="goTo('GuidedVisit')">
					<img class="mm-icon" src="@/assets/icons/guide.png" alt="">
					{{ (sideMenu && sideMenu.guideEnabled && sideMenu.locale && sideMenu.locale.guideText)
						? sideMenu.locale.guideText
						: $t('connect_with_guide') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.surveyEnabled && !sideMenu.surveyHighlighted)" @click="goTo('StudyQuestion')">
					<i class="mm-icon mm-icon-survey gray"/>
					{{ (sideMenu && sideMenu.surveyEnabled && sideMenu.locale && sideMenu.locale.surveyText)
						? sideMenu.locale.surveyText
						: $t('study') }}
				</li>
				
				<li v-if="!sideMenu || (sideMenu && sideMenu.downloadsEnabled && !sideMenu.downloadsHighlighted)" @click="goTo('Downloads')">
					<i class="mm-icon mm-icon-download gray"/>
					{{ (sideMenu && sideMenu.downloadsEnabled && sideMenu.locale && sideMenu.locale.downloadsText)
						? sideMenu.locale.downloadsText
						: $t('downloads') }}
				</li>
			</ul>
			
<!--			(MS-826) Change reset behaviour for a real-time update https://grupotks.atlassian.net/browse/MS-826 -->
<!--			<span class="version">v{{ version }}</span>-->
<!--			<div class="reset" @click="goTo('Reset')">-->
<!--				{{ $t('reset_museum') }}-->
<!--			</div>-->
			<footer>
				<button class="button-white" @click="$emit('close-menu')">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
			</footer>
		</aside>
	</transition>
</template>

<script>
import {setFullScreen} from "@/store/helpers";
import { mapState } from "vuex";

export default {
	name: "SideMenu",
	
	props: {
		idMuseum: String
	},
	
	data() {
		return {
			version: localStorage.getItem('version') || '',
		};
	},
	
	computed: {
		...mapState({
			sideMenu: state => state.museum.sideMenu,
		}),
	},
	
	methods: {
		goTo(section) {
			setFullScreen();
			if(section) {
				if(section === this.$router.currentRoute.name) {
					this.$emit('close-menu');
				} else {
					this.$router.push({ name: section, params: { idMuseum: this.idMuseum } });
				}
			}
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors.scss';

.slide-leave-active,
.slide-enter-active {
	transition: 0.5s;
}
.slide-enter {
	transform: translate(100%, 0);
}
.slide-leave-to {
	transform: translate(200%, 0);
}

.aside-content {
	position: absolute;
	top: 0;
	z-index: 1000;
	height: 100%;
	width: 100%;
	background-color: $gray-dark;
	color: white;
	
	ul {
		padding-left: 70px;
		width: 100%;
		height: calc(100% - 145px);
		overflow-y: auto;
		
		li {
			font-size: 18px;
			flex: 1;
			text-transform: uppercase;
			list-style: none;
			padding: 20px 20px 20px 0;
			height: 70px;
			display: flex;
			align-items: center;
			
			&:not(:last-child) {
				border-bottom: 1px solid white;
			}
			
			.mm-icon {
				margin-top: -3px;
				font-size: 30px;
				margin-right: 10px;
			}
			
			img.mm-icon {
				margin-top: -10px;
				width: 27px;
				margin-right: 13px;
				height: auto;
			}
		}
	}
	
	// (MS-826) Change reset behaviour for a real-time update https://grupotks.atlassian.net/browse/MS-826
	//.reset {
	//	position: fixed;
	//	bottom: 90px;
	//	width: 100%;
	//	color: white;
	//	background-color: $red;
	//	padding: 20px;
	//	text-align: center;
	//}
	//
	//.version {
	//	position: fixed;
	//	bottom: 155px;
	//	right: 10px;
	//	color: rgba(255, 255, 255, 0.2);
	//	font-size: 14px;
	//}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
			
			img {
				width: 15px;
				height: auto;
			}
		}
	}
}
</style>
