import axios from "axios";

const apiDomain = process.env.VUE_APP_API_URL;
const apiDomainV2 = process.env.VUE_APP_NEW_API_URL;
const apiURL = apiDomain + '/MUSEUMMATE/api/';
const apiURLV2 = apiDomainV2 + '/api/';

export default axios.create({
	baseURL: apiURL,
	headers: {
		"Authorization": "Basic " + Buffer.from(process.env.VUE_APP_API_USER + ':' + process.env.VUE_APP_API_PASSWORD, 'binary').toString('base64')
	}
});

export const axiosv2 = axios.create({
		baseURL: apiURLV2,
		headers:{"Accept": "application/json"}
	});