import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";

const state = {
	/**
	 * Array of the studyQuestions of the museum
	 * @type {Array}
	 */
	studyQuestions: [],

	/**
	 * Will be true when there is an error fetching studyQuestions from the museum
	 * @type {boolean}
	 */
	studyQuestionsError: false,
};

const getters = {

};

const actions = {
	/**
	 * Gets the studyQuestions of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 * @param {number} idArtwork Indicates which study questions should be get
	 */
	getStudyQuestions: ({ rootState, state, commit }, idArtwork) => {
		debug.open("getStudyQuestions");
		commit(mutationTypes.SET_STUDY_QUESTIONS_ERROR, false);

		if(rootState.museum.museum && rootState.museum.museum.studies) {
			let studyQuestions = [];

			rootState.museum.museum.studies.forEach((study) => {
				for(let studyQuestion of study.questions) {
					if(studyQuestion.idArtwork == idArtwork) {
						studyQuestion.locale = findLocalization(studyQuestion);
						studyQuestions.push(studyQuestion);
					}
				}
			});

			commit(mutationTypes.SET_STUDY_QUESTIONS, studyQuestions);
			debug.log("Found " + studyQuestions.length + " studyQuestions");

			if(state.studyQuestions.length < 1) {
				debug.log("There are no studyQuestions in this museum");
				commit(mutationTypes.SET_STUDY_QUESTIONS_ERROR, true);
			}
		} else {
			debug.log("There are no studyQuestions in this museum");
			commit(mutationTypes.SET_STUDY_QUESTIONS_ERROR, true);
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_STUDY_QUESTIONS]: (state, value) => state.studyQuestions = value,
	[mutationTypes.SET_STUDY_QUESTIONS_ERROR]: (state, value) => state.studyQuestionsError = value,
};

export default {
	moduleName: 'studyQuestion',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
