<template>
  <div class="wrapper">
    <template v-if="refreshingApp">
      {{ $t("loading_content") }}
    </template>
    <template v-else>
      <p>{{ $t("new_content_available") }}</p>
      <div class="options">
        <p class="btn cancel-refresh" @click="$emit('cancel')">
          {{ $t("no") }}
        </p>
        <p class="btn refresh" @click="$emit('refresh')">{{ $t("refresh") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NewContentAvailableToastr",

  props: {
    refreshingApp: Boolean,
  },
};
</script>

<style scoped lang="scss">
.wrapper {
	padding: 20px 20px;
	background-color: #333;
	color: white;
	display: inline-block;
	border-radius: 4px;
	
	.options {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		
		.btn {
			text-decoration: underline;
			cursor: pointer;
			
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
}
</style>
