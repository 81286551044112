import api from "./base/API.js";

const resource = "OneMinutes";
export default {
	get() {
		return api.get(resource);
	},

	getOneMinutes(id) {
		return api.get(resource + "/" + id);
	},
};
