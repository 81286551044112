import api from "./base/API.js";

const resource = "Artwork";
export default {
	get() {
		return api.get(resource);
	},

	getArtwork(id) {
		return api.get(resource + "/Detail/" + id);
	},

	getArtworksByMuseum(idMuseum) {
		return api.get(resource, { idMuseum: idMuseum });
	}
};
