/**
 * Transforms a blob into a Base64 string
 * @param {Blob} blob
 * @returns {Promise<string>}
 */
  const helpers = {
	/**
	 * Recursively transforms every url in an object to a Blob
	 * @param {Object} element
	 * @param {string} parentProperty
	 * @returns {Promise<void>}
	 */
	transform: async (element, parentProperty) => {
	  if (process.env.VUE_APP_INFO_MODE == "true") {
		console.log("[transform] Checking " + parentProperty, Object.assign({}, element));
	  }

	  try {
		for (let property in element) {
		  if (element.hasOwnProperty(property)) {
			if (typeof element[property] === 'string' && element[property].substring(0, 4) === 'http') {
			  let blob = await fetch(element[property]).then(r => r.blob());
			  element[property] = blob;
			  if (process.env.VUE_APP_INFO_MODE == "true") {
				console.log("[transform] Transformed to Blob " + property + " from " + parentProperty);
			  }
			}

			if (element[property] !== null
			  && !(element[property] instanceof Blob)
			  && element[property] instanceof Object
			  && property !== 'tags'
			  && property !== 'slides'
			  && property !== 'beacons') {

			  await helpers.transform(element[property], property);
			}
		  }
		}
	  } catch(e) {
		console.error(e);
	  }
	},

	/**
	 * Transform Blob properties to usable URLs
	 * @param {Object} object
	 * @returns {Promise<void>}
	 */
	propertiesToUrl: async (object) => {
	  try {
		for (let property in object) {
		  if (object.hasOwnProperty(property)) {
			/*if (object[property] instanceof Blob) {
			  if (process.env.VUE_APP_INFO_MODE == "true") {
				console.log("[propertiesToUrl] Checking ", Object.assign({}, object));
			  }
			  // object[property] = await blobToBase64(object[property]);
			  if (process.env.VUE_APP_INFO_MODE == "true") {
				console.log("[propertiesToUrl] Transformed to URL ", object[property]);
			  }
			}*/

			if (object[property] !== null
			  && object[property] instanceof Object) {
			  await helpers.propertiesToUrl(object[property]);
			}
		  }
		}
	  } catch(e) {
		console.error(e);
	  }
	}
  };

  export default helpers;
