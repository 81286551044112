<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<div v-if="!isLoading && sponsorError" class="error-code">
			{{ $t('no_sponsors') }}
		</div>
		
		<div v-else-if="!isLoading && isText" class="content-text">
			<h1>{{ sponsor.title }}</h1>
			<p>{{ sponsor.locale.content }}</p>
		</div>
		
		<div v-else-if="!isLoading && isImage" class="content-image">
			<div class="container">
				<div v-if="customization && customization.artworkCustomizationEnabled && customization.artworkBackgroundColor" class="bg-image"/>
				<img v-if="(customization && !customization.artworkCustomizationEnabled) || (customization && !customization.artworkBackgroundColor)" class="bg-image" :src="bgImage" alt="">
			</div>
			
			<img class="sponsor-image" :src="sponsor.url" alt="">
		</div>
		
		<div v-else-if="!isLoading && isAudio" class="content-audio">
			<div class="container">
				<div v-if="customization && customization.artworkCustomizationEnabled && customization.artworkBackgroundColor" class="bg-image"/>
				<img v-if="(customization && !customization.artworkCustomizationEnabled) || (customization && !customization.artworkBackgroundColor)" class="bg-image" :src="bgImage" alt="">
			</div>
			
			<div class="artwork-information">
				<h2>{{ sponsor.title }}</h2>
				
				<audio-player v-if="sponsor.locale.url" class="audio-player"
				              :file="sponsor.locale.url"
				              :text-color="customization && customization.artworkCustomizationEnabled && customization.artworkTextColor ? customization.artworkTextColor : '#FFFFFF'"
				              :icons-color="customization && customization.artworkCustomizationEnabled && customization.artworkTextColor ? customization.artworkTextColor : '#FFFFFF'"/>
			</div>
		</div>
		
		<div v-else-if="!isLoading && isVideo " class="content-video">
			<video controls autoplay playsinline>
				<source :src="sponsor.locale.url" type="video/mp4">
			</video>
		</div>
		
		<footer>
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-close big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import AudioPlayer from "@/components/AudioPlayer";
import {setFullScreen} from "@/store/helpers";

export default {
	name: "Resource",
	
	components: {
		Loading,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idArtwork: this.$route.params.idArtwork || "0",
			idResource: this.$route.params.idResource || "0",
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			sponsor: state => state.navigationSection.sponsor,
			sponsorError: state => state.navigationSection.sponsorError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('my_favourites_artworks');
			if(this.sponsor?.title) {
				locationName = this.sponsor.title;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		bgImage() {
			let bgImage = null;
			
			if(this.customization?.artworkCustomizationEnabled && this.customization.artworkBackgroundImageUrl) {
				bgImage = this.customization.artworkBackgroundImageUrl;
			} else {
				bgImage = this.museum.homeImageUrl;
			}
			
			if (!bgImage) {
				bgImage = '@/assets/home.jpg';
			}
			
			return bgImage;
		},
		
		isText() {
			return this.sponsor && this.sponsor.hasOwnProperty('resourceType') && this.sponsor.resourceType === 'TEXT' && this.sponsor.locale;
		},
		
		isAudio() {
			return this.sponsor && this.sponsor.hasOwnProperty('resourceType') && this.sponsor.resourceType === 'AUDIO' && this.sponsor.locale;
		},
		
		isVideo() {
			return this.sponsor && this.sponsor.hasOwnProperty('resourceType') && this.sponsor.resourceType === 'VIDEO' && this.sponsor.locale;
		},
		
		isImage() {
			return this.sponsor && this.sponsor.hasOwnProperty('resourceType') && this.sponsor.resourceType === 'IMAGE';
		},
		
		cssVars() {
			return {
				'--artwork-text-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkTextColor : (this.isText ? '#8f8f8f' : '#FFFFFF'),
				'--artwork-background-color': (this.bgImage) ? 'transparent' : this.customization?.artworkCustomizationEnabled ? this.customization.artworkBackgroundColor : '#FFFFFF',
			};
		}
	},
	
	created() {
		debug.log("[Resource] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.getNavigationSections();
					await this.findSponsors(this.idResource);
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('navigationSection', ['getNavigationSections', 'findSponsors']),
		
		goBack() {
			setFullScreen();
			this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors';

.content {
	width: 100%;
	height: 100%;
	background: var(--artwork-background-color);
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	.content-text {
		background-color: white;
		height: 100%;
		overflow-y: auto;
		
		h1 {
			font-weight: normal;
			text-transform: uppercase;
			border-bottom: 1px solid $gray-lightest;
			padding: 20px;
			width: 100%;
			position: fixed;
			top: 0;
			background-color: white;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		p {
			color: var(--artwork-text-color);
			font-size: 22px;
			padding: 80px 20px 110px 20px;
		}
	}
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
			background-color: var(--artwork-background-color);
		}
	}
	
	.content-image {
		display: flex;
		justify-content: center;
		z-index: 1;
		
		.sponsor-image {
			padding: 20px;
			width: 100%;
			height: auto;
		}
	}
	
	.content-audio {
		height: 100%;
		z-index: 1;
		
		.artwork-information {
			padding: 20px 20px 20px 20px;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: calc(100% - 90px);
			z-index: 1;
			
			h2 {
				font-weight: normal;
				font-size: 24px;
				margin-bottom: 30px;
				color: var(--artwork-text-color);
				border-bottom: 1px solid var(--artwork-text-color);
				z-index: 1;
			}
		}
	}
	
	.content-video {
		width: 100%;
		height: calc(100vh - 90px);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: black;
		
		video {
			width: 100%;
			height: auto;
			max-height: 100%;
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
