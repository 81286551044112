import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import {
    findLocalization,
    getArtworkAudio,
    getArtworkImage,
    getArtworkInfo,
} from "@/store/helpers";

const state = {
    /**
     * Object representation of the beacon
     * @type {Object}
     */
    hall: null,

    /**
     * Will be true when there is an error fetching the hall
     * @type {boolean}
     */
    hallError: false
};

const getters = {};

const actions = {
    /**
     * Gets a hall from the museum based on the given id
     * @param rootState
     * @param commit
     * @param {(number|string)} idHall - The id of the hall
     */
    getHall: async ({rootState, commit}, idHall) => {
        commit(mutationTypes.SET_HALL_ERROR, false);

        if (rootState.museum.museum && rootState.museum.museum.halls) {
            let hall = rootState.museum.museum.halls.find(hallArray => hallArray.idHall === parseInt(idHall));
            debug.open("getHall");
            debug.log((hall ? "Found" : "Not found") + " hall with id " + idHall + " in this museum");
            if (hall) {
                hall.locale = findLocalization(hall);
                debug.log("Hall " + hall.idHall + " locale " + (hall.locale ? "found" : "not found"));
                if (hall.locale) {
                    hall.name = hall.locale.name;
                }

                debug.open("Getting artworks info");
                hall.artworkImages = [];
                for (let artworkIndex in hall.artworkIds) {
                    let idArtwork = hall.artworkIds[artworkIndex];
                    debug.open("Artwork " + idArtwork);
                    let artworkImage = await getArtworkImage(rootState.museum.museum, idArtwork);
                    debug.log("Hall " + hall.idHall + " artwork " + idArtwork + " image " + (artworkImage ? "found" : "not found"));
                    let artworkAudio = await getArtworkAudio(rootState.museum.museum, idArtwork);
                    debug.log("Hall " + hall.idHall + " artwork " + idArtwork + " audio " + (artworkAudio ? "found" : "not found"));
                    let { artworkCode, artworkName, isOutstanding, showCode } = await getArtworkInfo(rootState.museum.museum, idArtwork);
                    hall.artworkImages.push({
                        idArtwork,
                        mainImageUrl: artworkImage,
                        mainAudioUrl: artworkAudio,
                        artworkCode,
                        artworkName,
                        isOutstanding,
                        showCode,
                    });
                    debug.close();
                }

                debug.open("Getting hall number");
                hall.number = -1;
                for(let i = 0; i <= rootState.museum.museum?.halls?.length; i++) {
                    if(rootState.museum.museum.halls[i].idHall === +hall.idHall) {
                        hall.number = i+1;
                        break;
                    }
                }
                debug.close();

                debug.close();
            }
            commit(mutationTypes.SET_HALL, hall);


        } else {
            debug.log("There are no halls in this museum");
            commit(mutationTypes.SET_HALL_ERROR, true);
        }
        debug.close();
    },
};

const mutations = {
    [mutationTypes.SET_HALL]: (state, value) => state.hall = value,
    [mutationTypes.SET_HALL_ERROR]: (state, value) => state.hallError = value
};

export default {
    moduleName: 'hall',
    statePropName: 'data',
    namespaced: true,

    state,
    getters,
    actions,
    mutations
};
