/* app.js */
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_NETWORK_ONLINE = 'SET_IS_NETWORK_ONLINE';
export const SET_SERVICE_WORKER_REGISTRATION_NEW_CONTENT = 'SET_SERVICE_WORKER_REGISTRATION_NEW_CONTENT';
export const SET_REFRESHING_APP = 'SET_REFRESHING_APP';
export const SET_VIEW_FROM = 'SET_VIEW_FROM';
export const SET_USER = 'SET_USER';
export const SET_CODE = 'SET_CODE';
export const SET_RESOURCES_OUT_OF_DB = 'SET_RESOURCES_OUT_OF_DB';
export const SET_VISIT = 'SET_VISIT';
export const SET_VISITV2 = 'SET_VISITV2';
export const ADD_ARTWORK_VISIT = 'ADD_ARTWORK_VISIT';
export const DELETE_ARTWORK_VISIT = 'DELETE_ARTWORK_VISIT';
export const UPDATE_LAST_ARTWORK_VISIT = 'UPDATE_ARTWORK_VISIT';
export const ADD_ROUTE_VISIT = 'ADD_ROUTE_VISIT';
export const DELETE_ROUTE_VISIT = 'DELETE_ROUTE_VISIT';
export const UPDATE_LAST_ROUTE_VISIT = 'UPDATE_ROUTE_VISIT';
export const UPDATE_ID_VISIT = 'UPDATE_ID_VISIT';

/* museum.js */
export const SET_MUSEUM = 'SET_MUSEUM';
export const SET_CUSTOMIZATION = 'SET_CUSTOMIZATION';
export const SET_SIDE_MENU = 'SET_SIDE_MENU';
export const SET_MUSEUM_FETCHING_ERROR = 'SET_MUSEUM_FETCHING_ERROR';
export const SET_SAVED_RESOURCES = 'SET_SAVED_RESOURCES';
export const SET_SAVING_RESOURCE = 'SET_SAVING_RESOURCE';
export const SET_TOTAL_RESOURCES = 'SET_TOTAL_RESOURCES';
export const DELETE_DOWNLOAD = 'DELETE_DOWNLOAD';
export const SET_DOWNLOADS = 'SET_DOWNLOADS';
export const SET_DOWNLOADS_ERROR = 'SET_DOWNLOADS_ERROR';
export const ADD_DOWNLOADS_PROGRESS = 'ADD_DOWNLOADS_PROGRESS';
export const DELETE_DOWNLOADS_PROGRESS = 'DELETE_DOWNLOADS_PROGRESS';

/* oneMinute.js */
export const SET_SLIDES = 'SET_SLIDES';
export const SET_SLIDE = 'SET_SLIDE';
export const SET_SLIDES_ERROR = 'SET_SLIDES_ERROR';
export const SET_SLIDE_ERROR = 'SET_SLIDE_ERROR';

/* artwork.js */
export const SET_ARTWORKS_ERROR = 'SET_ARTWORKS_ERROR';
export const SET_ARTWORK = 'SET_ARTWORK';
export const SET_ARTWORK_ERROR = 'SET_ARTWORK_ERROR';
export const SET_RESOURCE = 'SET_RESOURCE';
export const SET_RESOURCE_ERROR = 'SET_RESOURCE_ERROR';
export const SET_OUTSTANDING_ARTWORKS = 'SET_OUTSTANDING_ARTWORKS';
export const SET_OUTSTANDING_ARTWORKS_ERROR = 'SET_OUTSTANDING_ARTWORKS_ERROR';
export const SET_ARTWORK_VISIT = 'SET_ARTWORK_VISIT';
export const UPDATE_VISIT_END_DATE = 'UPDATE_VISIT_END_DATE';

/* route.js */
export const SET_ROUTES = 'SET_ROUTES';
export const SET_ROUTE = 'SET_ROUTE';
export const SET_ROUTES_ERROR = 'SET_ROUTES_ERROR';
export const SET_ROUTE_ERROR = 'SET_ROUTE_ERROR';
export const SET_HIGHLIGHTED_ROUTES = 'SET_HIGHLIGHTED_ROUTES';

/* oneMinute.js */
export const SET_ONE_MINUTES = 'SET_ONE_MINUTES';
export const SET_ONE_MINUTE = 'SET_ONE_MINUTE';
export const SET_ONE_MINUTES_ERROR = 'SET_ONE_MINUTES_ERROR';
export const SET_ONE_MINUTE_ERROR = 'SET_ONE_MINUTE_ERROR';

/* infoSection.js */
export const SET_INFO_SECTIONS = 'SET_INFO_SECTIONS';
export const SET_INFO_SECTION = 'SET_INFO_SECTION';
export const SET_INFO_SECTIONS_ERROR = 'SET_INFO_SECTIONS_ERROR';
export const SET_INFO_SECTION_ERROR = 'SET_INFO_SECTION_ERROR';

/* section.js */
export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_SECTIONS_ERROR = 'SET_SECTIONS_ERROR';

/* section.js */
export const SET_NAVIGATION_SECTIONS = 'SET_NAVIGATION_SECTIONS';
export const SET_NAVIGATION_SECTIONS_ERROR = 'SET_NAVIGATION_SECTIONS_ERROR';
export const SET_SPONSOR = 'SET_SPONSOR';
export const SET_SPONSOR_ERROR = 'SET_SPONSOR_ERROR';

/* studyQuestion.js */
export const SET_STUDY_QUESTIONS = 'SET_STUDY_QUESTIONS';
export const SET_STUDY_QUESTIONS_ERROR = 'SET_STUDY_QUESTIONS_ERROR';

/* timeline.js */
export const SET_TIMELINE = 'SET_TIMELINE';
export const SET_TIMELINE_ERROR = 'SET_TIMELINE_ERROR';

/* hall.js */
export const SET_HALL = 'SET_HALL';
export const SET_HALL_ERROR = 'SET_HALL_ERROR';

/* beacon.js */
export const SET_BEACON = 'SET_BEACON';
export const SET_BEACON_ERROR = 'SET_BEACON_ERROR';
