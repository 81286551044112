import api from "./base/API.js";

const resource = "KeyboardCustomization";
export default {
	get() {
		return api.get(resource);
	},

	getCustomizationByMuseum(idMuseum) {
		return api.get(resource + '?idMuseum=' + idMuseum);
	}
};
