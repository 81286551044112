import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization, findResourcesByLocale } from "@/store/helpers";
import idb from "@/api/base/idb";
import i18n from "@/i18n";
import {blobToUrl} from "@/store/helpers";

const state = {

	/**
	 * Will be true when there is an error fetching all the artworks from a museum
	 * @type {boolean}
	 */
	artworksError: false,

	/**
	 * Object representation of the artwork
	 * @type {Object}
	 */
	artwork: null,

	/**
	 * Will be true when there is an error fetching an artwork from a museum
	 * @type {boolean}
	 */
	artworkError: false,

	/**
	 * Object representation of the resource
	 * @type {Object}
	 */
	resource: null,

	/**
	 * Will be true when there is an error fetching a resource from an artwork
	 * @type {boolean}
	 */
	resourceError: false,

	/**
	 * Object representation of the outstanding artwork
	 * @type {Object}
	 */
	outstandingArtworks: null,

	/**
	 * Will be true when there is an error fetching the outstanding artworks
	 * @type {boolean}
	 */
	outstandingArtworksError: false,
};

const getters = {

};

const actions = {
	/**
	 * Clears all the artworks stored in the idb
	 * @returns {Promise<unknown>}
	 */
	deleteArtworksInDB: () => {
		return new Promise((resolve) => {
			debug.open("deleteArtworksInDB");
			debug.log("Started deleting artworks");

			idb.deleteAllInDB('artworks')
				.then(() => {
					debug.log("Artworks have been deleted from iDB");
					debug.close();
					resolve();
				});
		});
	},

	/**
	 * Gets a resource from an artwork based on the given id
	 * @param state
	 * @param commit
	 * @param {(number|string)} idResource - The id of the resource
	 */
	findArtworkResource: ({ state, commit }, idResource) => {
		debug.open("findArtworkResource");
		commit(mutationTypes.SET_RESOURCE_ERROR, false);
		if(state.artwork && state.artwork.hasOwnProperty('otherResources') && state.artwork.otherResources.length) {
			let resource = state.artwork.otherResources.find(resource => resource.idResource === parseInt(idResource));
			commit(mutationTypes.SET_RESOURCE, resource);

			if(!state.resource) {
				debug.log("Resource " + idResource + " could not be found in this artwork");
				commit(mutationTypes.SET_RESOURCE_ERROR, true);
			} else {
				debug.log("Resource " + idResource + " found");
				state.resource.locale = findLocalization(state.resource);
			}
		}
		debug.close();
	},

	/**
	 * Gets an artwork from a museum based on the given id
	 * @param rootState
	 * @param state
	 * @param commit
	 * @param {(number|string)} idArtwork - The id of the artwork
	 */
	findArtwork: async ({ rootState, state, commit }, idArtwork) => {
		debug.open("findArtwork");
		commit(mutationTypes.SET_ARTWORK_ERROR, false);
		let artwork = await idb.getFromDB(parseInt(idArtwork), 'artworks');
		commit(mutationTypes.SET_ARTWORK, artwork);

		if (!state.artwork) {
			artwork = rootState.museum.museum.artworks.find(artwork => artwork.idArtwork === parseInt(idArtwork));
			commit(mutationTypes.SET_ARTWORK, artwork);
			if(!state.artwork) {
				debug.log("Artwork " + idArtwork + " could not be found in this museum");
				commit(mutationTypes.SET_ARTWORK_ERROR, true);
			} else {

				if (artwork.mainImageUrl instanceof Blob) {
					// Convertir el mainImageURl a URL validad
					artwork.mainImageUrl = await blobToUrl(artwork.mainImageUrl);
				}
				debug.log("Artwork " + idArtwork + " could not be found in the iDB, but it exists in the museum, so it has been loaded with online resources");
				state.artwork.locale = findLocalization(state.artwork);
				const visitv2 = JSON.parse(localStorage.getItem('visitv2')) || {};
				const languagesV2 = JSON.parse(localStorage.getItem('languagesV2')) || [];
				const languagueCode = languagesV2.find(e => e.id === visitv2?.idLanguage);
				const actualLanguageCode = rootState.museum.sideMenu?.locale.languageCode || languagueCode.code;
				state.artwork.extraResources = findResourcesByLocale(state.artwork.otherResources, i18n.locale, actualLanguageCode);
			}
		} else {
			debug.log("Artwork " + idArtwork + " found");
			state.artwork.locale = findLocalization(state.artwork);
		}
		debug.close();
	},

	/**
	 * Gets the outstanding artworks of the museum
	 * @param store
	 */
	getOutstandingArtworks: ({ rootState, state, commit }) => {
		debug.open("getOutstandingArtworks");
		commit(mutationTypes.SET_OUTSTANDING_ARTWORKS_ERROR, false);

		let outstandingArtworks = [];
		let artworks = null;
		artworks = state.artworks ? state.artworks : rootState.museum.museum.artworks;
		for(let artwork of artworks) {
			if(artwork.outstanding) {
				artwork.locale = findLocalization(artwork);
				outstandingArtworks.push(artwork);
			}
		}

		commit(mutationTypes.SET_OUTSTANDING_ARTWORKS, outstandingArtworks);

		if(!outstandingArtworks.length) {
			debug.log("There are no outstanding artworks in this museum");
			commit(mutationTypes.SET_OUTSTANDING_ARTWORKS_ERROR, true);
		} else {
			debug.log("Got " + outstandingArtworks.length + " outstanding artworks in this museum");
		}
		debug.close();
	}
};

const mutations = {
	[mutationTypes.SET_ARTWORKS_ERROR]: (state, value) => state.artworksError = value,
	[mutationTypes.SET_ARTWORK]: (state, value) => state.artwork = value,
	[mutationTypes.SET_ARTWORK_ERROR]: (state, value) => state.artworkError = value,
	[mutationTypes.SET_RESOURCE]: (state, value) => state.resource = value,
	[mutationTypes.SET_RESOURCE_ERROR]: (state, value) => state.resourceError = value,
	[mutationTypes.SET_OUTSTANDING_ARTWORKS]: (state, value) => state.outstandingArtworks = value,
	[mutationTypes.SET_OUTSTANDING_ARTWORKS_ERROR]: (state, value) => state.outstandingArtworksError = value,
};

export default {
	moduleName: 'artwork',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
