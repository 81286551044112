<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>

		<loading :active.sync="isLoading"
		         :is-full-page="true"/>

		<side-menu v-if="!isLoading"
		           v-show="showMenu"
		           :id-museum="encrypted"
		           @close-menu="showMenu = false"/>

		<nav v-if="!isLoading">
			<h1>{{ sideMenu && sideMenu.locale ? sideMenu.locale.downloadsText : $t('downloads') }}</h1>
			<div v-if="museum.menuAvailable" class="menu" @click="showMenu = true; setFullScreen()">
				<img class="menu-icon" src="@/assets/icons/stayrelax_black.png" alt=""> <!-- TODO change this icon -->
			</div>
		</nav>

		<main v-if="!isLoading && !downloadsError" class="main">

<!--			TODO Implement navigator.storage (it suddenly stopped working) -->
<!--			<h2 v-if="storage" class="title">{{ $t('downloads_info') }}</h2>-->
<!--			<p v-if="storage && storage.usage">{{ $t('storage_used') }}: {{ storage.usage | humanSize }}</p>-->
<!--			<p v-if="storage && storage.quota">{{ $t('storage_free') }}: {{ storage.quota | humanSize }}</p>-->

			<h2 class="title">{{ $t('downloads_available') }}</h2>

			<!-- Routes -->
			<details class="section">
				<summary class="title">
					{{ sectionRoutes ? sectionRoutes.locale.title : $t('selected_routes') }}
					<i class="mm-icon mm-icon-arrow-right"/>
				</summary>
				<div class="item-scroll">
					<div v-for="route in downloads.routes" :key="route.idRoute"
					     class="item-container">
						<div class="item">
							<!-- Item name and preview -->
							<div class="item-preview">
								<img class="item-image" :src="getImageUrl(route.mainImageUrl)" alt="">
								<div class="item-name-container">
									<h3 v-if="route.locale" class="item-name">{{ route.locale.name }}</h3>
									<span class="info">{{ $t('artworks') | capitalize }}: {{ route.artworkCount }}</span>
									<div v-if="downloads.inProgress.routes[route.idRoute]" class="progress-bar-container">
										<span>{{ $t('downloading') }}</span>
										<progress :value="downloads.inProgress.routes[route.idRoute]"
											:max="route.artworkCount + 1">
											{{ ((downloads.inProgress.routes[route.idRoute] * 100) / route.artworkCount + 1)
											}}
										</progress>
									</div>
									<span v-if="downloads.downloaded.routes[route.idRoute]" class="downloaded">
										<i class="mm-icon mm-icon-download" />
										{{ $t('downloaded') }}
									</span>
								</div>
							</div>

							<!-- Download buttons -->
							<div class="item-actions">
								<button v-if="!downloads.downloaded.routes[route.idRoute]"
									:disabled="downloads.inProgress.routes[route.idRoute] > 0" class="button-download"
									@click="downloadRoute(route)">
									<i class="mm-icon mm-icon-download green" />
								</button>
								<button v-if="downloads.downloaded.routes[route.idRoute]" class="button-delete"
									@click="deleteRouteDownload(route)">
									<i class="mm-icon mm-icon-delete red" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</details>

			<!-- Exhibitions -->
			<details class="section">
				<summary class="title">
					{{ sectionExhibitions ? sectionExhibitions.locale.title : $t('temporary_exhibition') }}
					<i class="mm-icon mm-icon-arrow-right" />
				</summary>
				<div class="item-scroll">
					<div v-for="exhibition in downloads.exhibitions" :key="exhibition.idRoute" class="item-container">
						<div class="item">
							<!-- Item name and preview -->
							<div class="item-preview">
								<img class="item-image" :src="exhibition.mainImageUrl" alt="">
								<div class="item-name-container">
									<h3 v-if="exhibition.locale" class="item-name">{{ exhibition.locale.name }}</h3>
									<span class="info">{{ $t('artworks') | capitalize }}: {{ exhibition.artworkCount
									}}</span>
									<div v-if="downloads.inProgress.exhibitions[exhibition.idRoute]"
										class="progress-bar-container">
										<span>{{ $t('downloading') }}</span>
										<progress :value="downloads.inProgress.exhibitions[exhibition.idRoute]"
											:max="exhibition.artworkCount + 1">
											{{ ((downloads.inProgress.exhibitions[exhibition.idRoute] * 100) /
												exhibition.artworkCount + 1) }}
										</progress>
									</div>
									<span v-if="downloads.downloaded.exhibitions[exhibition.idRoute]" class="downloaded">
										<i class="mm-icon mm-icon-download" />
										{{ $t('downloaded') }}
									</span>
								</div>
							</div>

							<!-- Download buttons -->
							<div class="item-actions">
								<button v-if="!downloads.downloaded.exhibitions[exhibition.idRoute]"
									:disabled="downloads.inProgress.exhibitions[exhibition.idRoute] > 0"
									class="button-download" @click="downloadRoute(exhibition, true)">
									<i class="mm-icon mm-icon-download green" />
								</button>
								<button v-if="downloads.downloaded.exhibitions[exhibition.idRoute]" class="button-delete"
									@click="deleteRouteDownload(exhibition, true)">
									<i class="mm-icon mm-icon-delete red" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</details>

			<!-- Timeline -->
			<details v-if="downloads.timelines.length" class="section">
				<summary class="title">
					{{ downloads.timelines[0].locale.name }}
					<i class="mm-icon mm-icon-arrow-right" />
				</summary>
				<div class="item-scroll">
					<div v-for="timeline in downloads.timelines" :key="timeline.idTimeline" class="item-container">
						<div class="item">
							<!-- Item name and preview -->
							<div class="item-preview">
								<img class="item-image" :src="timeline.mainImageUrl" alt="">
								<div class="item-name-container">
									<h3 v-if="timeline.locale" class="item-name">{{ timeline.locale.name }}</h3>
									<span class="info">{{ $t('halls') | capitalize }}: {{ timeline.hallCount }}</span>
									<span class="info">{{ $t('artworks') | capitalize }}: {{ timeline.artworkCount }}</span>
									<div v-if="downloads.inProgress.timelines[timeline.idTimeline]"
										class="progress-bar-container">
										<span>{{ $t('downloading') }}</span>
										<progress :value="downloads.inProgress.timelines[timeline.idTimeline]"
											:max="timeline.artworkCount + timeline.hallCount + 1">
											{{ ((downloads.inProgress.timelines[timeline.idTimeline] * 100) /
												(timeline.artworkCount + timeline.hallCount + 1)) }}
										</progress>
									</div>
									<span v-if="downloads.downloaded.timelines[timeline.idTimeline]" class="downloaded">
										<i class="mm-icon mm-icon-download" />
										{{ $t('downloaded') }}
									</span>
								</div>
							</div>

							<!-- Download buttons -->
							<div class="item-actions">
								<button v-if="!downloads.downloaded.timelines[timeline.idTimeline]"
									:disabled="downloads.inProgress.timelines[timeline.idTimeline] > 0"
									class="button-download" @click="downloadTimeline(timeline)">
									<i class="mm-icon mm-icon-download green" />
								</button>
								<button v-if="downloads.downloaded.timelines[timeline.idTimeline]" class="button-delete"
									@click="deleteTimelineDownload(timeline)">
									<i class="mm-icon mm-icon-delete red" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</details>

			<!-- Extras -->
			<details class="section">
				<summary class="title">
					{{ $t('stay_relax') }}
					<i class="mm-icon mm-icon-arrow-right" />
				</summary>
				<div class="item-scroll">
					<div v-for="extra in filteredExtras" :key="extra" class="item-container">
						<div class="item">
							<!-- Item name and preview -->
							<div class="item-preview">
								<div class="item-name-container">
									<h3 class="item-name">{{ $t(extra) | capitalize }}</h3>
									<span class="info">{{ $t('elements') | capitalize }}: {{ museum[extra].length }}</span>
									<div v-if="downloads.inProgress.extras[extra]" class="progress-bar-container">
										<span>{{ $t('downloading') }}</span>
										<progress :value="downloads.inProgress.extras[extra]" :max="museum[extra].length">
											{{ ((downloads.inProgress.extras[extra] * 100) / museum[extra].length) }}
										</progress>
									</div>
									<span v-if="downloads.downloaded.extras[extra]" class="downloaded">
										<i class="mm-icon mm-icon-download" />
										{{ $t('downloaded') }}
									</span>
								</div>
							</div>

							<!-- Download buttons -->
							<div class="item-actions">
								<button v-if="!downloads.downloaded.extras[extra]"
									:disabled="downloads.inProgress.extras[extra]" class="button-download"
									@click="downloadExtra(extra)">
									<i class="mm-icon mm-icon-download green" />
								</button>
								<button v-if="downloads.downloaded.extras[extra]" class="button-delete"
									@click="deleteExtraDownload(extra)">
									<i class="mm-icon mm-icon-delete red" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</details>

			<!-- All -->
			<details class="section">
				<summary class="title">
					Descargar Todo
					<i class="mm-icon mm-icon-arrow-right" />
				</summary>
				<div class="item-scroll">
					<div class="item-container">
						<div class="item">
							<!-- Item name and preview -->
							<div class="item-preview">
								<div class="item-name-container">
									<h3 class="item-name">Download All</h3>
									<div v-if="downloadProgress < totalCount && totalCount !== 0" class="progress-bar-container">
										<span>{{ $t('downloading') }}</span>
										<progress :value="downloadProgress" :max="totalCount">
											{{ ((downloadProgress * 100) / totalCount) }}
										</progress>
									</div>
									<span v-if="downloadProgress === totalCount && totalCount !== 0 " class="downloaded">
										<i class="mm-icon mm-icon-download" />
										{{ $t('downloaded') }}
									</span>
								</div>
							</div>

							<!-- Download buttons -->
							<div class="item-actions">
								<button v-if="downloadProgress === 0 && downloadProgress === totalCount" :disabled="false"
									class="button-download" @click="downloadAllFunction()">
									<i class="mm-icon mm-icon-download green" />
								</button>
								<button v-if="downloadProgress === totalCount && totalCount !== 0" class="button-delete"
									@click="() => alertSurprise('Todo descargado')">
									<i class="mm-icon mm-icon-delete red" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</details>
		</main>

		<main v-if="downloadsError" class="error-code">
			{{ $t('no_resources') }}
		</main>

		<footer>
			<button class="button-white" @click="goToOptions()">
				<i class="mm-icon mm-icon-arrow-back big" />
			</button>
		</footer>
	</div>
</template>

<script>
const { debug } = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import { setFullScreen } from "@/store/helpers";
import SideMenu from "@/components/SideMenu";
import idb from "@/api/base/idb";

export default {
	name: "Downloads",

	components: {
		SideMenu,
		Loading
	},

	filters: {
		capitalize: function (value) {
			if (!value) return '';
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},

		humanSize: function (value) {
			//See more https://stackoverflow.com/a/14919494
			let bytes = Math.abs(value);
			const thresh = 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}

			const units = ['Kb', 'Mb', 'Gb', 'Tb'];
			let unitIndex = -1;
			const r = 10 ** 2;

			do {
				bytes /= thresh;
				++unitIndex;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && unitIndex < units.length - 1);


			return bytes.toFixed(2) + ' ' + units[unitIndex];
		}
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
			storage: null,
			downloadAll: 0,
			totalCount: 0,
			downloadProgress: 0,
			sectionExhibitions: {
				arrowColor: "#FFFFFF",
				lineColor: "#17779B",
				locale: {
					title: this.$i18n.t('temporary_exhibition')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionRoutes: {
				arrowColor: "#FFFFFF",
				lineColor: "#D1B490",
				locale: {
					title: this.$i18n.t('selected_routes')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionTimeline: {
				arrowColor: "#FFFFFF",
				lineColor: "#94AF49",
				locale: {
					title: this.$i18n.t('time_line')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			downloads: state => state.museum.downloads,
			downloadsError: state => state.museum.downloadsError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
			sideMenu: state => state.museum.sideMenu,
		}),

		pageTitle() {
			let locationName = this.$i18n.t('downloads');

			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";

			return locationName + ' | ' + museumName;
		},

		footerCSSVars() {
			return {
			};
		},

		filteredExtras() {
			if (this.downloads) {
				return this.downloads.extras.filter(extra => this.museum[extra] !== null && this.museum[extra].length);
			} else {
				return [];
			}
		},
	},

	created() {
		debug.open("Downloads");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Downloads', params: { idMuseum: this.encrypted } });

		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];

			this.loadMuseum(this.idMuseum)
				.then(async () => {
					this.getNavigationSections();

					let foundSection = this.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
					this.sectionExhibitions = foundSection ? foundSection : this.sectionExhibitions;
					foundSection = this.navigationSections.find((s) => s.sectionType === 'ROUTES');
					this.sectionRoutes = foundSection ? foundSection : this.sectionRoutes;
					foundSection = this.navigationSections.find((s) => s.sectionType === 'TIMELINE');
					this.sectionTimeline = foundSection ? foundSection : this.sectionTimeline;

					await this.getDownloads();

					navigator.storage?.estimate().then((data) => this.storage = data);
				})
				.finally(() => {
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch (e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},

	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('navigationSection', ['getNavigationSections']),
		...mapActions('museum', ['getDownloads', 'storeInIDB', 'deleteElementFromIDB']),

		setFullScreen(to = null) {
			setFullScreen();
			if (to) {
				this.$router.push(to);
			}
		},

		goToOptions() {
			setFullScreen();
			this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
		},

		async downloadRoute(originalRoute, isExhibition = false) {
			let route = Object.assign({}, originalRoute);
			debug.open("Downloading the " + isExhibition ? 'exhibition' : 'route', route);

			//Mark element as not downloaded yet
			this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOAD, {
				store: isExhibition ? 'exhibitions' : 'routes',
				element: route.idRoute
			});

			//Mark element as download in progress
			this.$store.commit('museum/' + mutationTypes.ADD_DOWNLOADS_PROGRESS, {
				store: isExhibition ? 'exhibitions' : 'routes',
				element: route.idRoute
			});

			//Save element own properties
			await this.storeInIDB({
				storeName: 'routes',
				element: route
			});
			debug.log("Downloaded the " + (isExhibition ? 'exhibition' : 'route') + " itself");

			//Find artworks inside the route or exhibition
			let artworks = this.museum.artworks.filter(museumArtwork => route.artworkIDs.includes(museumArtwork.idArtwork));

			//Save artworks in the iDB
			debug.open("Downloading " + artworks.length + " artworks");
			for (const artwork of artworks) {
				await this.storeInIDB({
					storeName: "artworks",
					element: artwork
				});

				this.$store.commit('museum/' + mutationTypes.ADD_DOWNLOADS_PROGRESS, {
					store: isExhibition ? 'exhibitions' : 'routes',
					element: route.idRoute
				});
			}
			debug.close();

			setTimeout(() => {
				//Mark element as not in progress
				this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOADS_PROGRESS, {
					store: isExhibition ? 'exhibitions' : 'routes',
					element: route.idRoute
				});
			}, 500);
			debug.close();
		},

		async downloadTimeline(originalTimeline) {
			let timeline = Object.assign({}, originalTimeline);
			debug.open("Downloading the timeline: ", timeline);

			//Mark element as not downloaded yet
			this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOAD, {
				store: 'timelines',
				element: timeline.idTimeline
			});

			//Mark element as download in progress
			this.$store.commit('museum/' + mutationTypes.ADD_DOWNLOADS_PROGRESS, {
				store: 'timelines',
				element: timeline.idTimeline
			});

			//Save element own properties
			await this.storeInIDB({
				storeName: 'timeline',
				element: timeline
			});
			debug.log("Downloaded the timeline itself");

			//Find halls inside the timeline
			let stepsToDownload = timeline.steps.filter(step => !!step.idHall).map(hallStep => hallStep.idHall);
			let halls = this.museum.halls.filter(museumHall => stepsToDownload.includes(museumHall.idHall));

			//Save halls and their artworks in the iDB
			debug.open("Downloading " + halls.length + " halls");
			for (const hall of halls) {
				await this.storeInIDB({
					storeName: "halls",
					element: hall
				});

				let artworks = this.museum.artworks.filter(museumArtwork => hall.artworkIds.includes(museumArtwork.idArtwork));
				debug.open("Downloading " + artworks.length + " artworks inside this hall");
				for (const artwork of artworks) {
					await this.storeInIDB({
						storeName: "artworks",
						element: artwork
					});

					this.$store.commit('museum/' + mutationTypes.ADD_DOWNLOADS_PROGRESS, {
						store: 'timelines',
						element: timeline.idTimeline
					});
				}
				debug.close();

				this.$store.commit('museum/' + mutationTypes.ADD_DOWNLOADS_PROGRESS, {
					store: 'timelines',
					element: timeline.idTimeline
				});
			}
			debug.close();

			setTimeout(() => {
				//Mark element as not in progress
				this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOADS_PROGRESS, {
					store: 'timelines',
					element: timeline.idTimeline
				});
			}, 500);

			debug.close();
		},

		async downloadExtra(extra) {
			debug.open("Downloading ", extra);

			//Mark element as not downloaded yet
			this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOAD, {
				store: 'extrass',
				element: extra.idRoute
			});

			//Save extra elements in the iDB
			let elements = this.museum[extra];
			debug.open("Downloading " + elements.length + " elements");
			for (const element of elements) {
				await this.storeInIDB({
					storeName: extra,
					element: element
				});

				this.$store.commit('museum/' + mutationTypes.ADD_DOWNLOADS_PROGRESS, {
					store: 'extras',
					element: extra
				});
			}
			debug.close();

			setTimeout(() => {
				//Mark element as not in progress
				this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOADS_PROGRESS, {
					store: 'extras',
					element: extra
				});
			}, 500);
			debug.close();
		},

		alertSurprise (message) {
			alert(message);
		},

		async downloadAllFunction() {
			let emptyNumber = 0;
			// this.downloadRoute(route);
			// this.downloadTimeline(timeline);
			// this.downloadExtra(extra);
			// route in downloads.routes
			// timeline in downloads.timelines
			// extra in filteredExtras

			//JUST FOR COUNTING
			for (const originalRoute of this.downloads.routes) {
				let route = Object.assign({}, originalRoute);
				let artworks = this.museum.artworks.filter(museumArtwork => route.artworkIDs.includes(museumArtwork.idArtwork));
				for (const artwork of artworks) {
					emptyNumber = emptyNumber + 1;
				}
			}

      this.downloads.timelines.forEach(originalTimeline => {
				let timeline = Object.assign({}, originalTimeline);
				let stepsToDownload = timeline.steps.filter(step => !!step.idHall).map(hallStep => hallStep.idHall);
				let halls = this.museum.halls.filter(museumHall => stepsToDownload.includes(museumHall.idHall));
				for (const hall of halls) {
					emptyNumber = emptyNumber + 1;
					let artworks = this.museum.artworks.filter(museumArtwork => hall.artworkIds.includes(museumArtwork.idArtwork));
					for (const artwork of artworks) {
						emptyNumber = emptyNumber + 1;
					}

				}
			});

			this.filteredExtras.forEach(extra => {
				let elements = this.museum[extra];
				for (const element of elements) {
					emptyNumber = emptyNumber + 1;
				}
			});

			this.totalCount = emptyNumber;

			//DOWNLOAD
			for (const originalRoute of this.downloads.routes) {
				let route = Object.assign({}, originalRoute);
				await this.storeInIDB({
					storeName: 'routes',
					element: route
				});
				let artworks = this.museum.artworks.filter(museumArtwork => route.artworkIDs.includes(museumArtwork.idArtwork));
				for (const artwork of artworks) {
					await this.storeInIDB({
						storeName: "artworks",
						element: artwork
					});
					this.downloadProgress = this.downloadProgress + 1;
				}
			}

      this.downloads.timelines.forEach(async (originalTimeline) => {
				let timeline = Object.assign({}, originalTimeline);
				let stepsToDownload = timeline.steps.filter(step => !!step.idHall).map(hallStep => hallStep.idHall);
				let halls = this.museum.halls.filter(museumHall => stepsToDownload.includes(museumHall.idHall));
				for (const hall of halls) {
					await this.storeInIDB({
						storeName: "halls",
						element: hall
					});

					let artworks = this.museum.artworks.filter(museumArtwork => hall.artworkIds.includes(museumArtwork.idArtwork));
					debug.open("Downloading " + artworks.length + " artworks inside this hall");
					for (const artwork of artworks) {
						await this.storeInIDB({
							storeName: "artworks",
							element: artwork
						});
						this.downloadProgress = this.downloadProgress + 1;
					}

				}
			});

			this.filteredExtras.forEach(async (extra) => {
				let elements = this.museum[extra];
				for (const element of elements) {
					await this.storeInIDB({
						storeName: extra,
						element: element
					});

					this.downloadProgress = this.downloadProgress + 1;
				}
			});
		},

		async deleteRouteDownload(route, isExhibition) {
			this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOAD, { store: isExhibition ? 'exhibitions' : 'routes', element: route.idRoute });
			await this.deleteElementFromIDB({ elementID: route.idRoute, storeName: 'routes' });
			debug.open("Deleted " + (isExhibition ? 'exhibition' : 'route') + ' from iDB', route);

			let artworksToDelete = route.artworkIDs;
			//Find what artworks are safe to delete (not being used by another downloaded route)
			let routes = await idb.getAllFromDB('routes');
			for (const museumRoute of routes) {
				if (museumRoute.idRoute !== route.idRoute) {
					artworksToDelete = artworksToDelete.filter(artworkID => !museumRoute.artworkIDs.includes(artworkID));
				}
			}

			if (this.downloads.timelines.length) {
				//Find what artworks are safe to delete (not being used by the timeline)
				let timeline = await idb.getFromDB(this.downloads.timelines[0].idTimeline, 'timeline');
				if (timeline) {
					artworksToDelete = artworksToDelete.filter(artworkID => !timeline.artworkIDs.includes(artworkID));
				}
			}

			debug.log("Find " + artworksToDelete.length + " safe artworks to delete from iDB", artworksToDelete);

			for (const artworkID of artworksToDelete) {
				await this.deleteElementFromIDB({ elementID: artworkID, storeName: 'artworks' });
			}
			debug.log("Deleted artworks from iDB");

			debug.close();
		},

		async deleteTimelineDownload(timeline) {
			this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOAD, { store: 'timeline', element: timeline.idTimeline });
			await this.deleteElementFromIDB({ elementID: timeline.idTimeline, storeName: 'timeline' });
			debug.open("Deleted the timeline from iDB", timeline);

			let artworksToDelete = timeline.artworkIDs;
			//Find what artworks are safe to delete (not being used by a downloaded route)
			let routes = await idb.getAllFromDB('routes');
			for (const museumRoute of routes) {
				artworksToDelete = artworksToDelete.filter(artworkID => !museumRoute.artworkIDs.includes(artworkID));
			}

			debug.log("Find " + artworksToDelete.length + " safe artworks to delete from iDB", artworksToDelete);

			for (const artworkID of artworksToDelete) {
				await this.deleteElementFromIDB({ elementID: artworkID, storeName: 'artworks' });
			}
			debug.log("Deleted artworks from iDB");
		},

		async deleteExtraDownload(extra) {
			this.$store.commit('museum/' + mutationTypes.DELETE_DOWNLOAD, { store: 'extras', element: extra });
			await this.deleteElementFromIDB({ storeName: extra });
		},

    getImageUrl(url) {
      if (url instanceof Blob){
        return URL.createObjectURL(url);
      }

      return url;
    }
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	nav {
		position: fixed;
		top: 0;
		height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		border-bottom: 1px solid #cfcfd0;

		h1 {
			font-weight: normal;
			font-size: 22px;
			padding: 0 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;

			.menu-icon {
				width: 40px;
				height: auto;
			}
		}
	}

	.error-code {
		background-color: white;
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}

	main {
		background-color: white;
		width: 100%;
		padding: 90px 20px 110px;
		height: 100%;
		flex: 2;
		overflow-y: auto;

		h2.title {
			margin-bottom: 10px;
		}

		.section {
			width: 100%;
			margin-bottom: 20px;

			&[open] {
				.title {
					.mm-icon {
						transform: rotate(90deg);
						margin-right: 3px;
					}
				}
			}

			.title {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-transform: uppercase;
				color: $gray-light;
				font-weight: normal;
				padding: 15px 10px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
				margin-bottom: 5px;
				list-style: none none;

				.mm-icon {
					margin-top: -8px;
				}
			}

			.item-scroll {
				display: flex;
				overflow-x: auto;
				margin: 0 -20px;

				.item-container {
					min-width: 200px;
					width: 200px;
					position: relative;

					&:not(:last-child) {
						margin-right: 10px;
					}

					&:last-child {
						padding-right: 20px;
						min-width: 220px;
					}

					&:first-child {
						margin-left: 20px;
					}

					.item {
						display: flex;
						flex-direction: column;

						.item-preview {
							height: 200px;
							position: relative;

							.item-image {
								height: 100%;
								width: 100%;
								object-fit: cover;
							}

							.item-name-container {
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								height: 100%;
								background: rgba(0, 0, 0, 0.7);
								padding: 10px;
								display: flex;
								flex-direction: column;

								.item-name {
									font-size: 22px;
									color: white;
									margin-bottom: 10px;
								}

								span {
									color: white;
									display: flex;
								}

								.progress-bar-container {
									margin-top: auto;
									width: 100%;

									span {
										font-size: 13px;
									}

									progress[value] {
										-webkit-appearance: none;
										-moz-appearance: none;
										appearance: none;

										border: none;

										/* For IE10 */
										color: rgba(#FFFFFF, 0.4);

										width: 100%;
										background-color: rgba(#FFFFFF, 0.4);

										&::-webkit-progress-bar {
											background-color: rgba(#FFFFFF, 0.4);
											border-radius: 2px;
										}

										&::-webkit-progress-value {
											background-color: rgba(#FFFFFF, 0.8);
											border-radius: 2px;

											-webkit-animation: animate-stripes 5s linear infinite;
											animation: animate-stripes 5s linear infinite;
										}

										@-webkit-keyframes animate-stripes {
											100% {
												background-position: -100px 0px;
											}
										}

										@keyframes animate-stripes {
											100% {
												background-position: -100px 0px;
											}
										}
									}
								}

								.downloaded {
									margin-top: auto;
									display: flex;
									align-items: center;
									justify-content: center;

									.mm-icon {
										margin-top: -4px;
										margin-right: 5px;
										font-size: 16px;
									}
								}
							}
						}

						.item-actions {
							display: flex;
							align-items: center;
							justify-content: center;

							button {
								flex-grow: 1;
								border: 1px solid #cfcfd0;
								border-top: none;
								background-color: white;
								height: 50px;

								&:not(:last-child) {
									border-right: none;
								}

								.mm-icon {
									font-size: 20px;
								}

								&:disabled {
									background-color: lighten($gray-light, 30%);
									color: $gray-dark;

									.mm-icon {
										color: $gray-dark;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;

			img {
				width: 15px;
				height: auto;
			}
		}
	}
}
</style>
