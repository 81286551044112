import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import VueHeadful from "vue-headful";
Vue.component('vue-headful', VueHeadful);

import VueSweetAlert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetAlert2);

import '@/misc/handle-network-status';
import '@/misc/register-service-worker';

import 'pwacompat';

Vue.config.productionTip = false;
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
	bootstrap: false,
});

// eslint-disable-next-line vue/require-name-property
new Vue({
	router,
	store,
	i18n,
	...App
}).$mount('#app');
