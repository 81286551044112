import GenericClass from "@/models/generic-class";

export default class Downloads extends GenericClass {
	routes; // Array;
	exhibitions; // Array;
	timelines; // Array;
	extras; // Array
	inProgress; // Object
	downloaded; // Object

	constructor(routes, exhibitions, timelines, extras, downloaded) {
		super();
		this.routes = routes;
		this.exhibitions = exhibitions;
		this.timelines = timelines;
		this.extras = extras;
		this.inProgress = {
			routes: {},
			exhibitions: {},
			timelines: {},
			extras: {},
		};
		this.downloaded = downloaded;
	}
}
