import Vue from 'vue';
import Vuex from 'vuex';

import app from "@/store/modules/app";
import museum from "@/store/modules/museum";
import artwork from "@/store/modules/artwork";
import route from "@/store/modules/route";
import oneMinute from "@/store/modules/oneMinute";
import infoSection from "@/store/modules/infoSection";
import timeline from "@/store/modules/timeline";
import hall from "@/store/modules/hall";
import beacon from "@/store/modules/beacon";
import slide from "@/store/modules/slide";
import section from "@/store/modules/section";
import navigationSection from "@/store/modules/navigationSection";
import studyQuestion from "@/store/modules/studyQuestion";

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',

	modules: {
		app,
		museum,
		artwork,
		route,
		oneMinute,
		infoSection,
		timeline,
		hall,
		beacon,
		slide,
		section,
		navigationSection,
		studyQuestion
	}
});
