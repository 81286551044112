import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";

const state = {
	/**
	 * Array of the sections of the museum
	 * @type {Array}
	 */
	sections: [],

	/**
	 * Will be true when there is an error fetching sections from the museum
	 * @type {boolean}
	 */
	sectionsError: false,
};

const getters = {

};

const actions = {
	/**
	 * Gets the sections of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 */
	getSections: ({ rootState, state, commit }) => {
		debug.open("getSections");
		commit(mutationTypes.SET_SECTIONS_ERROR, false);

		if(rootState.museum.museum && rootState.museum.museum.sections) {
			let sections = [];

			for(let index in rootState.museum.museum.sections) {
				let section = rootState.museum.museum.sections[index];
				debug.open("Section " + section.idSection);

				section.locale = findLocalization(section);
				section.artworks = [];
				for(let artworkIndex in section.artworkIds) {
					let artwork = null;
					artwork = rootState.museum.museum.artworks.find(artwork => artwork.idArtwork === section.artworkIds[artworkIndex]);
					if(artwork) {
						debug.log("Found artwork " + artwork.idArtwork + " in the museum!");
						artwork.locale = findLocalization(artwork);
						section.artworks.push(artwork);
					} else {
						debug.log("Couldn't find artwork " + section.artworkIds[artworkIndex] + " in the museum!");
					}
				}

				sections.push(section);
				debug.close();
			}

			commit(mutationTypes.SET_SECTIONS, sections);
			debug.log("Found " + sections.length + " sections");

			if(state.sections.length < 1) {
				debug.log("There are no sections in this museum");
				commit(mutationTypes.SET_SECTIONS_ERROR, true);
			}
		} else {
			debug.log("There are no sections in this museum");
			commit(mutationTypes.SET_SECTIONS_ERROR, true);
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_SECTIONS]: (state, value) => state.sections = value,
	[mutationTypes.SET_SECTIONS_ERROR]: (state, value) => state.sectionsError = value,
};

export default {
	moduleName: 'section',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
