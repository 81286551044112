export const debug = {

	/**
	 * Logs a message to the console.
	 * @param {string} message
	 */
	log: (message = "") => {
		const canLog = process.env.VUE_APP_DEBUG_MODE == "true" || window.canLog;
		if (canLog) {
			console.log(message);
		}
	},

	/**
	 * Logs an item in table mode to the console
	 * @param item
	 */
	table: (item = {}) => {
		const canLog = process.env.VUE_APP_DEBUG_MODE == "true" || window.canLog;
		if(canLog) {
			console.table(item);
		}
	},

	/**
	 * Starts a console log group
	 * @param {string} name
	 */
	open: (name = "") => {
		const canLog = process.env.VUE_APP_DEBUG_MODE == "true" || window.canLog;
		if (canLog) {
			console.groupCollapsed(name);
		}
	},

	/**
	 * Ends a console log group
	 */
	close: () => {
		const canLog = process.env.VUE_APP_DEBUG_MODE == "true" || window.canLog;
		if (canLog) {
			console.groupEnd();
		}
	}

};
