export default class GenericClass {
	parseJSON(json, normalize = true) {
		for(let property in json) {
			let normalizedProperty = normalize ? camelize(property) : property;
			if(this.hasOwnProperty(normalizedProperty)) {
				this[normalizedProperty] = json[property];
			}
		}
	}
}

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index === 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}
