import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";
import { getArtworkImage } from "@/store/helpers";
import { getArtworkAudio } from "@/store/helpers";
import idb from "@/api/base/idb";
import Timeline from "@/models/timeline";

const state = {
	/**
	 * Object representation of the current timeline
	 * @type {Object}
	 */
	timeline: null,

	/**
	 * Will be true when there is an error fetching the current timeline
	 * @type {boolean}
	 */
	timelineError: false,
};

const getters = {

};

const actions = {
	/**
	 * Processes a timeline with the needed properties
	 * @param store
	 * @param originalTimeline
	 * @returns {Promise<Timeline>}
	 */
	processTimeline: ({rootState}, originalTimeline) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async(resolve) => {
			let timeline = new Timeline();
			timeline.parseJSON(originalTimeline);
			timeline.locale = findLocalization(timeline);
			timeline.artworkCount = 0;
			timeline.hallCount = 0;
			timeline.artworkIDs = [];
			timeline.halls = [];

			for (let index in timeline.steps) {
				let step = timeline.steps[index];
				step.index = index;

				if (!step.idHall) {
					step.locale = findLocalization(step);
				} else {
					timeline.hallCount++;
					if (rootState.museum.museum.hasOwnProperty('halls') && Array.isArray(rootState.museum.museum.halls)) {
						let hall = rootState.museum.museum.halls.find(hall => hall.idHall === step.idHall);
						if (hall) {
							timeline.artworkCount += hall.artworkIds.length;
							timeline.artworkIDs = timeline.artworkIDs.concat(hall.artworkIds);

							hall.locale = findLocalization(hall);
							debug.log("Hall " + hall.idHall + " locale " + (hall.locale ? "found" : "not found"));
							if (hall.locale) {
								let name = hall.locale.name;
								hall.upperName = name?.substring(0, name.lastIndexOf(" "));
								hall.bottomName = name?.substring(name.lastIndexOf(" "));
							}

							hall.artworkImages = [];
							for (const artworkID of hall.artworkIds) {
								let artworkImage = await getArtworkImage(rootState.museum.museum, artworkID);
								debug.log("Hall " + hall.idHall + " artwork " + artworkID + " image " + (artworkImage ? "found" : "not found"));
								let artworkAudio = await getArtworkAudio(rootState.museum.museum, artworkID);
								debug.log("Hall " + hall.idHall + " artwork " + artworkID + " audio " + (artworkAudio ? "found" : "not found"));
								hall.artworkImages.push({
									idArtwork: artworkID,
									mainImageUrl: artworkImage,
									mainAudioUrl: artworkAudio,
								});
							}

							timeline.halls.push(hall);
						}
					}
				}
			}

			resolve(timeline);
		});
	},

	/**
	 * Gets the timeline from the museum if it has any
	 * @param rootState
	 * @param commit
	 * @param dispatch
	 */
	getTimeline: async ({ rootState, commit, dispatch }) => {
		debug.open("getTimeline");
		commit(mutationTypes.SET_TIMELINE_ERROR, false);

		if(rootState.museum.museum && rootState.museum.museum.timeline) {
			let timeline = rootState.museum.museum.timeline;

			let storedTimeline = await idb.getFromDB(timeline.idTimeline, 'timeline');
			if(storedTimeline) {
				debug.log("Found the timeline (id: " + storedTimeline.idTimeline + ') in the iDB');
				timeline = storedTimeline;
			} else {
				timeline = await dispatch('processTimeline', timeline);
			}

			commit(mutationTypes.SET_TIMELINE, timeline);
			debug.log("Found Timeline " + timeline.idTimeline);
		} else {
			debug.log("There are no timelines in this museum");
			commit(mutationTypes.SET_TIMELINE_ERROR, true);
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_TIMELINE]: (state, value) => state.timeline = value,
	[mutationTypes.SET_TIMELINE_ERROR]: (state, value) => state.timelineError = value,
};

export default {
	moduleName: 'timeline',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
