<script>
export default {
    name: "PlayButton"
};
</script>

<template>
    <!-- <?xml version="1.0" encoding="utf-8"?> -->
    <!-- Generator: Adobe Illustrator 28.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 116 116" style="enable-background:new 0 0 116 116;" xml:space="preserve">
        <g id="Capa_2"/>

        <g id="Capa_1">
            <g>
                <rect class="st0" width="0" height="0" />
                <polygon class="st1" points="77.3,58 38.7,35.6 38.7,80.5 		" />
            </g>
            <g>
                <polygon class="st0" points="-62.3,58 -32.3,75.5 -32.3,40.5 		" />
                <polygon class="st0" points="-92.5,58 -62.4,75.5 -62.4,40.5 		" />
            </g>
            <g>
                <polygon class="st0" points="192.5,58 162.4,40.5 162.4,75.5 		" />
                <polygon class="st0" points="222.6,58 192.6,40.5 192.6,75.5 		" />
            </g>
        </g>
    </svg>

</template>

<style type="text/css">
.st0 {
    fill: #E85F31;
}
.st1 {
    fill: #FFFFFF;
}
</style>