import {debug} from "@/misc/debug";

const DB_NAME = 'MuseumMate';
const DB_VERSION = 2;
let DB;
let indexedDB = window.indexedDB || window.msIndexedDB;


export default {
	/**
	 * Returns a Promise with the indexedDB ready
	 * @returns {Promise<unknown>}
	 */
	async getDB() {
		return new Promise((resolve, reject) => {
			if(DB) {
				return resolve(DB);
			}

			let request = indexedDB.open(DB_NAME, DB_VERSION);

			request.onerror = (error) => {
				reject(error);
			};

			request.onsuccess = (event) => {
				DB = event.target.result;
				resolve(DB);
			};

			request.onupgradeneeded = (event) => {
				let db = event.target.result;

				if(event.oldVersion < 1) { //Original
					db.createObjectStore("museums", {keyPath: 'idMuseum'});
					db.createObjectStore("artworks", {keyPath: 'idArtwork'});
					db.createObjectStore("halls", {keyPath: 'idHall'});
					db.createObjectStore("infoSections", {keyPath: 'idInfoSection'});
					db.createObjectStore("oneMinutes", {keyPath: 'idOneMinute'});
					db.createObjectStore("routes", {keyPath: 'idRoute'});
					db.createObjectStore("sections", {keyPath: 'idSection'});
					db.createObjectStore("timeline", {keyPath: 'idTimeline'});
				}

				if(event.oldVersion < 2) { //2020.09.25
					db.createObjectStore("navigationSections", {keyPath: 'idNavigationSection'});
				}
			};
		});
	},

	/**
	 * Gets one element by its id from the indexedDB
	 * @param id
	 * @param objectStore
	 * @returns {Promise<unknown>}
	 */
	async getFromDB(id, objectStore = 'museums') {
		if(id) {
			let db = await this.getDB();
			return new Promise((resolve, reject) => {

				let transaction = db.transaction([objectStore], 'readonly');

				let store = transaction.objectStore(objectStore);
				let request = store.get(id);

				request.onsuccess = () => {
					debug.log("[idb:getFromDB] " + objectStore + " " + id + " was " + (request.result ? "" : "not ") + "found in the iDB");
					resolve(request.result);
				};

				request.onerror = (event) => {
					console.error('Error de IndexedDB:', event.target.error);
					reject(event.target.error);
				};
			});
		} else {
			return null;
		}
	},

	/**
	 *
	 * @param objectStore
	 * @returns {Promise<unknown>}
	 */
	async getAllFromDB(objectStore) {
		let db = await this.getDB();

		return new Promise((resolve) => {
			let transaction = db.transaction([objectStore], 'readonly');
			transaction.oncomplete = () => {
				resolve(elements);
			};

			let store = transaction.objectStore(objectStore);
			let elements = [];

			// We use cursors because methods like getAll/getAllKeys return an array of keys/values.
			// But an object storage can be huge, bigger than the available memory. Then getAll will
			// fail to get all records as an array.
			store.openCursor().onsuccess = (event) => {
				let cursor = event.target.result;
				if(cursor) {
					elements.push(cursor.value);
					cursor.continue();
				}
			};
		});
	},

	/**
	 * Deletes one element by its id from the indexedDB
	 * @param id
	 * @param objectStore
	 * @returns {Promise<unknown>}
	 */
	async deleteFromDB(id, objectStore = 'museums') {
		if(id) {
			let db = await this.getDB();
			return new Promise((resolve) => {
				let transaction = db.transaction([objectStore], 'readwrite');

				let store = transaction.objectStore(objectStore);
				let request = store.delete(id);

				request.onsuccess = () => {
					debug.log("[idb:deleteFromDB] " + objectStore + " " + id + " was deleted from the iDB");
					resolve(request.result);
				};
			});
		} else {
			return null;
		}
	},

	async deleteAllInDB(objectStore) {
		let db = await this.getDB();

		return new Promise((resolve, reject) => {
			let transaction = db.transaction([objectStore], 'readwrite');
			transaction.oncomplete = (event) => {
				debug.log("Store " + objectStore + " cleared!", event);
				resolve();
			};

			transaction.onerror = (error) => {
				debug.log("Error!", error);
				reject();
			};

			let store = transaction.objectStore(objectStore);
			store.clear();
		});
	},

	async deleteIDB() {
		return new Promise((resolve, reject) => {
			let DBDeleteRequest = indexedDB.deleteDatabase(DB_NAME);

			DBDeleteRequest.onerror = function(event) {
				debug.log("Error deleting database.");
				reject(event);
			};

			DBDeleteRequest.onsuccess = function(event) {
				debug.log("Database deleted successfully");
				debug.log(event.result);
				resolve(event);
			};
		});
	},

	/**
	 * Saves an element to a table in the indexedDB
	 * @param model
	 * @param objectStore
	 * @returns {Promise<unknown>}
	 */
	async saveToDB(model, objectStore = 'museums') {
		let db = await this.getDB();

		return new Promise((resolve, reject) => {
			let transaction = db.transaction([objectStore], 'readwrite');
			transaction.oncomplete = () => {
				debug.log('[idb:saveToDB] Saved!', model);
				resolve();
			};

			transaction.onabort = (abort) => {
				debug.log("[idb:saveToDB] Abort!", abort);
				reject();
			};

			try {
				let store = transaction.objectStore(objectStore);
				store.put(model);
			} catch(error) {
				debug.log("[idb:saveToDB] Error saving in iDB!", error);
				reject();
			}
		});
	}
};
