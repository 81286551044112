<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<main :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
			<vue-headful
				title="MuseumMate"
			/>
			<img class="logo" src="@/assets/logo.png" alt="">
		</main>
		
		<button class="button error">{{ $t("error_museum_deactivated") }}</button>
	</div>
</template>

<script>
import backgroundImage from '@/assets/home.jpg';

export default {
	name: "NotFound",
	data() {
		return {
			backgroundImage: backgroundImage,
		};
	},
	computed: {
		pageTitle() {
			return 'MuseumMate';
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors';

.content {
	height: 100%;
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: var(--bg-image);
		background-color: white;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		
		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: auto;
			padding-top: 75px;
			
			.logo-image {
				width: 70%;
			}
		}
	}
	
	.button {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 30px;
		border: none;
		background-color: $primary-blue;
		color: white;
		text-transform: uppercase;
	}
}
</style>
