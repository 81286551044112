import api from "./base/API.js";

const resource = "Beacon";
export default {
	get() {
		return api.get(resource);
	},

	getBeacon(id) {
		return api.get(resource + "/" + id);
	},
};
